export const state = () => ({
  data: [],
});

export const mutations = {
  SET_DATA(context, data) {
    context.data = data;
  }
}

export const actions = {
  async fetch (context) {
    const response = await this.$axios('schemas?fields=id,title,title_single,is_readonly');
    context.commit('SET_DATA', response.data);
  }
}
