
export default {
  props: {
    small: {
      type: Boolean,
      default: false
    },
    dataCy: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    menu: false,
  })
}
